header {
  nav {
    box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.06);
  }
  .red-bar {
    width: 100%;
    height: 6px;
    background-color: $primary;
    position: relative;
    border-radius: 6px;
    margin: auto;

    &::after,
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 6px;
      background: $primary-dark;
      border-radius: 50%;
    }
    &::before {
      right: 0;
      left: unset;
    }
  }

  .category {
    color: $primary;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 1.38;
  }
  .product-name {
    font-weight: 300;
    font-size: 1.25rem;
    line-height: 1.3;
  }
  .header-sticky {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }
  .old-price {
    color: $primary;
    text-decoration: line-through;
    margin-right: 0.4375rem;
    font-size: 1rem;
  }
  .new-price {
    font-weight: bold;
    font-size: 1.2rem;
  }
  .free-shipping {
    margin-top: 0.3125rem;
    font-size: 1rem;
  }
}

.header-sticky {
  background-color: white;
  z-index: 5;
}

.fixed {
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.06);
  position: fixed;
  top: 0;
}
.navbar-brand {
  padding-top: 20px;
  padding-bottom: 14px;
}

@include media-breakpoint-down(lg) {
  .header-sticky {
    position: fixed !important;
    bottom: 0;
    top: unset !important;
    &.top {
      position: static !important;
      bottom: unset;
      top: 0 !important;
    }
  }
  .top-bar {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
