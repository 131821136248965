#menu-nebbiogeno {
  z-index: 4;
  width: 100%;
  background-color: white;
  position: fixed;
  min-height: 70px;
  .title {
    font-weight: 600;
  }
}
.no-bg {
  background-image: none !important;
}
.fp-tableCell {
  padding-top: 70px;
}
.fullpage-wrapper {
  .bg-container {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: fixed;
    &.hidden {
      display: none;
    }
  }
  #botique-bg {
    object-fit: cover;
    height: 100%;
    width: 100%;
    transform: scale(1.3);
    transition: transform 1.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition-delay: 0.2s;
    &.hidden {
      visibility: hidden;
    }
  }
  #donna-cassa {
    object-fit: cover;
    height: 100%;
    width: 100%;
    z-index: 2;
  }
  .overlay {
    background-color: black;
    opacity: 0.6;
    position: fixed;
    height: 100%;
    width: 100%;
    transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition-delay: 0.3s;
  }
}
.text-white * {
  color: white;
}

#p-title {
  max-width: 43ch;
  margin: auto;
  font-size: 1.5rem;
}
.nebbia {
  width: 150%;
  height: 150%;
  position: absolute;
  object-fit: contain;
  transition: all 2.3s ease-out;
}
.nebbia-dx {
  top: -30%;
  transform: translateX(100%);

  right: 0;
  &.translate {
    transition-delay: 0.8s;
    transform: translateX(10%) scale(1.6);
  }
}
.nebbia-sx {
  top: -15%;
  transform: translateX(-100%) scaleX(-1);
  left: 0;
  &.translate {
    transition-delay: 1s;
    transform: translateX(-10%) scaleX(-1) scale(1.8);
  }
}
#white-bg {
  height: 100%;
  width: 100%;
  background-color: white;
  opacity: 0;
  transition: all 1.5s linear;
  transition-delay: 1s;
  &.active {
    transition-delay: 3.2s;
    opacity: 1;
  }
}

.title-specs {
  font-size: 2.25rem;
}
.spec-item-container {
  img {
    margin-bottom: 0.9375rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  p {
    font-size: 1.25rem;
    font-weight: 300;
  }
}

.card-nebbiogeno {
  box-shadow: 2px 12px 40px 0px #00000012;
  position: relative;
  border-end-end-radius: 1.875rem;
  height: 100%;
  .bg-img {
    width: 100%;
    height: 210px;
    object-fit: cover;
    border-end-end-radius: 42px;
  }
  .purifog {
    position: absolute;
    top: 100px;
    left: 0;
    &.purifog-500 {
      height: 173px;
      width: 173px;
    }
    &.purifog-200 {
      height: 173px;
      width: 173px;
    }
    &.purifog-1500 {
      top: 59px;
      height: 214px;
      width: 214px;
    }
  }
  .ribbon {
    margin-top: 1.375rem;
    margin-bottom: 2.0625rem;
    margin-right: 0.625rem;
    text-align: end;
    color: white;
    font-size: 14px;
    font-weight: 600;
    .ribbon-wrapper {
      padding: 0.375rem 0.625rem;
    }
  }
  h3 {
    font-size: 2rem;
    margin-bottom: 1.25rem;
  }
  p,
  li,
  li span {
    font-size: 1.125rem;
    line-height: 1.33;
    color: black;
  }
  li span {
    font-weight: normal;
  }
  .content {
    padding-inline: 1.25rem;
    padding-bottom: 2.5rem;
  }
}

.semibold {
  font-weight: 600;
}
b {
  font-weight: bold;
}

.second-p {
  margin-top: 1.875rem;
}

[data-aos="slide-in"] {
  opacity: 0;
  transform: translateX(-400%);
  transition-property: transform, opacity;

  &.aos-animate {
    transform: translateX(0);
    opacity: 1;
  }
}
[data-aos="slide-in-1"] {
  opacity: 0;
  transform: translateX(-400%);
  transition-property: transform, opacity;

  &.aos-animate {
    transform: translateX(0);
    opacity: 1;
  }
}
[data-aos="slide-in-2"] {
  opacity: 0;
  transform: translateX(-400%);
  transition-property: transform, opacity;

  &.aos-animate {
    transform: translateX(0);
    opacity: 1;
  }
}

.igienizzante {
  p {
    margin-top: 1.25rem;
    font-size: 1.375rem;
  }
}

.specs-desktop {
  background-image: url("../images/donna_cassa.png");
  background-position: center;
  background-size: cover;
}

.hidden {
  display: none;
}
.lottie-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 70%;
  filter: blur(3px);
}

.table {
  background-color: white;
  border-radius: 9px;
  border-collapse: collapse;
  box-shadow: 0 0 0 1px #c5281c;
  font-size: 1rem;
  th {
    background-color: #c5281c;
    color: white;
    padding: 20px 12px !important;
    font-size: 1.25rem;
    &:first-child {
      border-top-left-radius: 9px;
    }
    &:last-child {
      border-top-right-radius: 9px;
    }
  }
  * {
    border: none !important;
  }
  .desc {
    font-weight: bold;
  }
}

#dettagli-prodotto {
  h4 {
    font-size: 1rem;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  p {
    font-size: 1rem;
    padding-bottom: 10px;
    color: #707070;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      background: url("../images/icon_check.svg") no-repeat left top;
      height: 40px;
      padding-left: 28px;
      padding-top: 5px;
      display: block;
    }
  }
}
.btn-close {
  background: url(../images/cross_icon.svg) center/1.5em auto no-repeat;
  opacity: 1;
}
#modalPreventivo {
  .modal-content {
    border-radius: 0;
    border-bottom-right-radius: 24px;
    border: none;
  }
  .modal-header {
    border: none;
  }
  .modal-body {
    margin-bottom: 30px;
    margin-inline: 100px;
  }
}

.mobile-specs-wrapper {
  display: none;
}
.info-aggiuntive {
  margin-bottom: 30px;
}

#accordionFaqs {
  .accordion-body {
    padding-top: 0;
  }
  .accordion-button {
    padding: 0.9375rem 0;
  }
}
//media queries
@include media-breakpoint-down(md) {
  #menu-nebbiogeno {
    .btn {
      font-size: 0.75rem;
      padding-top: 11px;
      padding-bottom: 11px;
    }
  }
  h1 {
    max-width: none;
    line-height: 1.2;
  }
  h2 {
    line-height: 1.2;
  }
  #p-title {
    font-size: 20px;
    line-height: 1.2;
  }
  #nebbiogeno-bagliore {
    height: auto;
    width: 180px;
  }
  .funzione h2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .igienizzante {
    img {
      height: 72px;
      width: 72px;
    }
    p {
      font-size: 1.25rem;
    }
  }
  .title-specs {
    font-size: 2rem;
    margin-bottom: 0.75rem;
  }
  #first-spec-container {
    margin-bottom: 1.875rem;
  }
  .spec-item-container {
    img {
      height: 108px;
      width: 108px;
      margin-bottom: 0;
    }
    h3 {
      font-size: 1.5rem;
      margin-top: 0.9375rem;
    }
  }
  .lottie-container {
    height: 100%;
    transform: translate(-50%, -50%);
    width: 200%;
    left: 50%;
  }
  .table {
    font-size: 0.875rem;
    th {
      font-size: 1.125rem;
      line-height: 1.35;
    }
  }
  #modalPreventivo {
    .modal-content {
      border-radius: 0;
      border-bottom-right-radius: 24px;
    }
    .modal-header {
      border: none;
    }
    .modal-body {
      margin-bottom: 30px;
      margin-inline: 10px;
    }
  }
  .info-aggiuntive {
    margin-bottom: 0px;
  }
}

@include media-breakpoint-down(lg) {
  .scopri-purifog {
    p {
      font-size: 1.25rem;
    }
  }

  #nebbiogeno {
    overflow: hidden;
    body {
      overflow: hidden;
      height: 100%;
      scroll-behavior: smooth;
    }
  }

  .section {
    height: calc((var(--vh, 1vh) * 100));
    position: relative;
    padding-top: 70px;
    &.snap {
      scroll-snap-align: start;
      scroll-snap-stop: always;
    }
  }

  .scopri-purifog {
    padding-top: 110px;
    height: auto;
  }
  #faq-footer {
    height: auto;
  }
  #fullpage {
    position: relative;
    height: calc((var(--vh, 1vh) * 100));
    overflow-y: auto;
    &.snapping {
      scroll-snap-type: y mandatory;
      -webkit-overflow-scrolling: touch;
    }
  }
  #section-igienizzante {
    height: auto;
  }
  .table {
    th {
      text-align: center;
    }
  }
  .specs-mobile {
    z-index: 1;
  }
  .mobile-specs-wrapper {
    position: relative;
    display: block;
    .boiner {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      #donna {
        object-fit: cover;
        height: 100vh;
        width: 100%;
        position: sticky;
        z-index: 1;
        top: 70px;
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  #purifog-tab {
    margin-bottom: 1.875rem;
    margin-top: 1.875rem;
    .nav-link {
      color: #706f6f;
      border-radius: 16px;
      box-shadow: 2px 2px 10px 0px #00000010;
      font-size: 0.9375rem;
      padding: 0.625rem 1.75rem;
      transition: all 0.2s ease-out;
      &.active {
        background-color: #4225b0;
        color: white;
      }
    }
  }
  .card-nebbiogeno {
    box-shadow: none;
    text-align: left;
    .content {
      padding-inline: 0;
    }
    h3 {
      margin-top: 5rem;
    }
    p {
      font-size: 1.125rem;
    }
    .accordion {
      margin-top: 30px;
      .accordion-button {
        font-size: 1rem;
        font-weight: 600;
      }
    }
  }
}
