h1,
#titolo-scoprire {
  font-size: 3.5rem;
}

h2 {
  font-size: 2.25rem;
  margin-bottom: 1.25rem;
  line-height: 1.39;
}
h3 {
  font-size: 1.75rem;
  line-height: 1.21;
  margin-bottom: 0.3125rem;
}
p {
  line-height: normal;
  margin-bottom: 0;
}
.spec {
  height: 320px;
  object-fit: cover;
  width: 100%;
}
.round-border-b-left {
  border-bottom-left-radius: 2.5rem;
}
.round-border-b-right {
  border-bottom-right-radius: 2.5rem;
}
#col-prezzo {
  line-height: 1.25;
}
#img-airsafe {
  margin-top: 2.6rem;
  margin-bottom: -3.125rem;
  max-width: 100%;
}

.main-p {
  font-size: 1.5rem;
  line-height: 1.25;
  margin-bottom: 20px;
  span {
    font-weight: bold;
  }
}
.secondary-p {
  font-weight: 300;
  font-size: 1.125rem;
}

#container-assistenza {
  h2 {
    max-width: 28ch;
    line-height: 1.06;
  }
  p {
    font-size: 1.375rem;
    font-weight: normal;
  }
  p:not(:last-of-type) {
    margin-bottom: 2.5rem;
  }
}
#titolo-app,
#titolo-ordina {
  max-width: 28ch;
  line-height: 1.17;
  margin-left: auto;
  margin-right: auto;
}
#img-assistenza-container {
  position: relative;
  height: 100%;
  border-end-start-radius: 40px;
  img {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
#airsafe-poster {
  height: 480px;
  width: 100%;
  background-image: url("../images/airsafe_desk.png");
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: cover;
}
//smartphone animation
#tabs-app {
  .nav-link {
    display: flex;

    &:not(:last-of-type) {
      margin-bottom: 2rem;
    }
    .bar,
    .progress {
      width: 6px;

      background-color: #e5e5e5;
      margin-right: 20px;
      border-radius: 15px;
      position: relative;
      &::before,
      &::after {
        content: "";
        background-color: #cccccc;
        height: 6px;
        width: 6px;
        border-radius: 50%;
        z-index: 1;
        display: inline-block;
        position: absolute;
      }
      &::after {
        bottom: 0;
      }
    }
    .progress {
      background-color: $primary;
      height: 0;
      &::after,
      &::before {
        background-color: $primary-dark;
        z-index: 2;
      }
    }
    h3 {
      font-size: 1.25rem;
      color: #cccccc;
      margin: 0;
      text-align: left;
      line-height: 1.3;
    }
    p {
      margin: 0;
    }
    .description {
      line-height: 1.33;
      font-size: 1.125rem;
      opacity: 0;
      margin-top: 0rem;
      overflow: hidden;
      transition: all 0.3s linear;
      padding-bottom: 5px;
      max-width: 28ch;
      text-align: left;
    }
    color: black;
    &.active {
      &:hover .progress {
        animation-play-state: paused;
      }
      h3 {
        color: black;
      }
      color: black;
      background-color: white;
      .description {
        opacity: 1;
        margin-top: 0.625rem;
      }
      .progress {
        animation: 3.3s linear 0s 1 normal forwards progress-animation;
      }
    }
  }
}

@keyframes progress-animation {
  from {
    height: 6px;
  }
  to {
    height: 100%;
  }
}

@keyframes slider-progress-animation {
  from {
    width: 8px;
  }
  to {
    width: 30px;
  }
}

.app-download-img {
  object-fit: cover;
}
.swiper-pagination,
.swiper-pagination-scelta {
  display: none;
}

.accordion-button {
  padding: 0.875rem 0;
  font-size: 20px;
  font-weight: bold;
  &:not(.collapsed) {
    background-color: transparent;
    color: black;
    box-shadow: none;
    &::after {
      background-image: url("../images/chevron-down.svg");
      width: 1.5rem;
      height: 1.5rem;
      background-size: 100%;
    }
  }
  &:focus {
    border-color: #cccccc;
    box-shadow: 0 0 0 0.25rem rgba(128, 128, 128, 0.178);
  }
  &::after {
    background-image: url("../images/chevron-down.svg");
    width: 1.5rem;
    height: 1.5rem;
    background-size: 100%;
  }
}
.accordion * {
  background-color: transparent;
}
.accordion-flush .accordion-item {
  border-color: #979797;
  &:last-child {
    border-bottom: 1px solid #979797;
  }
  &:first-child {
    border-top: 1px solid #979797;
  }
}

.accordion-body {
  font-size: 1rem;
  padding-left: 0;
  padding-right: 0;
  line-height: 1.5;
}

#scegliere-airsafe-container {
  h3 {
    margin-top: 1.125rem;
    margin-bottom: 1.25rem;
  }
  p {
    margin-bottom: 0;
    max-width: 27ch;
    margin-left: auto;
    margin-right: auto;
  }
}

// media queries

@include media-breakpoint-up(md) {
  .swiper-slide.col-lg-4 {
    width: 33.33333%;
  }
}

@include media-breakpoint-down(xl) {
  h1,
  #titolo-scoprire {
    font-size: 2.8125rem;
  }
  #airsafe-poster {
    background-position-y: bottom;
  }
}
@include media-breakpoint-down(lg) {
  .spec {
    height: 280px;
  }
  .swiper-pagination,
  .swiper-pagination-scelta {
    display: block;
  }
  .swiper-pagination-scelta {
    margin-top: 20px;
  }
  #tabs-app {
    position: relative;
    width: 100%;
    .nav-link {
      position: absolute;
      width: 100%;
      .content {
        opacity: 0;
        h3 {
          text-align: center;
        }
        .description {
          margin-top: 1rem;

          max-width: unset;
          text-align: center;
        }
      }
      &.active {
        .content {
          opacity: 1;

          .description {
            height: auto !important;
          }
        }
      }
    }
  }
  .swiper {
    // overflow-x: hidden;
    overflow: hidden;
    width: 100%;
    .swiper-slide {
      width: 350px;
      text-align: center;
      font-size: 18px;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .swiper-pagination-bullet {
    border-radius: 5px;
    height: 6px;
    width: 6px;
    transition: opacity 0.5s, background-color 0.5s, width 0.5s;

    transition-delay: 0.5s, 0.5s, 0s;
  }
  .swiper-pagination-bullet-active {
    opacity: 1;
    width: 30px;
    background-color: $primary;
    position: relative;

    transition-delay: 0s;
    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 6px;
      background-color: $primary-dark;
      border-radius: 5px;
      left: 0;
      animation: 3.3s linear 0s 1 normal forwards slider-progress-animation;
    }
  }
  .p-bottom {
    height: 4.2775rem;
  }

  h1,
  #titolo-scoprire {
    font-size: 2.4375rem;
  }
}

@include media-breakpoint-down(md) {
  .spec {
    height: 227px;
  }
  h1,
  #titolo-scoprire {
    font-size: 2rem;
    max-width: 15ch;
    margin-left: auto;
    margin-right: auto;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.125rem;
    margin-top: 1.25rem;
    margin-bottom: 0.625rem;
  }
  p {
    font-size: 1rem;
  }

  .main-p {
    font-size: 1.375rem;
  }
  .secondary-p {
    font-size: 1.125rem;
  }
  #img-airsafe {
    margin-top: 2.875rem;
    margin-bottom: 0;
  }
  #container-assistenza {
    h2 {
      line-height: 1.19;
    }
  }
  #img-assistenza-container {
    height: 227px;
    margin-top: 1.5rem;
    margin-bottom: 1.25rem;
  }
  #titolo-ordina {
    margin-bottom: 10px;
  }
}
