$grid-breakpoints: (
  xs: 0px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1500px,
);
$primary: #c5281c;
$primary-dark: #8d0000;
$secondary: #6bb61c;
$secondary-dark: #589517;
$transition-base: all 0.2s ease-in-out;
$border-radius-base: 5px;
$box-shadow-base: 0 0 25px rgba(0, 0, 0, 0.08);
$table-striped-bg: #f3f3f3;
$table-cell-padding-y: 14px;
$table-cell-padding-x: 0.75rem;
$table-cell-padding-y-sm: 14px;
$table-cell-padding-x-sm: 0.75rem;
$spacer: 0.9375rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  //3.75px
  2: $spacer * 0.5,
  //7.5px
  3: $spacer,
  //15px
  4: 2.5rem,
  //40px
  5: 3.125rem,
  //50px
  6: $spacer * 4,
  //60px
  7: $spacer * 5,
  //75px
  8: $spacer * 6,
  //90px
  9: 6.25rem,
  //100px
  10: $spacer * 8,
  //120px
  11: $spacer * 9,
  //135px
  12: $spacer * 10,
  //150px
  13: $spacer * 11,
  //165px
  14: $spacer * 12,
  //180px
);

$container-max-widths: (
  sm: 500px,
  md: 700px,
  lg: 750px,
  xl: 1000px,
  xxl: 1100px,
);

// general scss
html {
  scroll-behavior: smooth;
}
* {
  line-height: 1;
}

$font-family-base: "Nunito", sans-serif;
