@import "./node_modules/bootstrap/scss/functions";

// Bootstrap Override
@import "base/variables";

// Bootstrap Component

@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins";
@import "./node_modules/bootstrap/scss/utilities";
@import "./node_modules/bootstrap/scss/root";
@import "./node_modules/bootstrap/scss/reboot";
@import "./node_modules/bootstrap/scss/type";
// @import "./node_modules/bootstrap/scss/images";
@import "./node_modules/bootstrap/scss/containers";
@import "./node_modules/bootstrap/scss/grid";
@import "./node_modules/bootstrap/scss/tables";
// @import "./node_modules/bootstrap/scss/forms";
@import "./node_modules/bootstrap/scss/buttons";
@import "./node_modules/bootstrap/scss/transitions";
@import "./node_modules/bootstrap/scss/dropdown";
@import "./node_modules/bootstrap/scss/button-group";
@import "./node_modules/bootstrap/scss/nav";
@import "./node_modules/bootstrap/scss/navbar";
// @import "./node_modules/bootstrap/scss/card";
@import "./node_modules/bootstrap/scss/accordion";
// @import "./node_modules/bootstrap/scss/breadcrumb";
// @import "./node_modules/bootstrap/scss/pagination";
// @import "./node_modules/bootstrap/scss/badge";
// @import "./node_modules/bootstrap/scss/alert";
// @import "./node_modules/bootstrap/scss/progress";
// @import "./node_modules/bootstrap/scss/list-group";
@import "./node_modules/bootstrap/scss/close";
// @import "./node_modules/bootstrap/scss/toasts";
@import "./node_modules/bootstrap/scss/modal";
// @import "./node_modules/bootstrap/scss/tooltip";
// @import "./node_modules/bootstrap/scss/popover";
// @import "./node_modules/bootstrap/scss/carousel";
// @import "./node_modules/bootstrap/scss/spinners";
@import "./node_modules/bootstrap/scss/helpers";
@import "./node_modules/bootstrap/scss/utilities/api";

//Libraries
@import "./node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "./node_modules/swiper/swiper-bundle.min";
@import "./node_modules/aos/dist/aos";
@import "./node_modules/fullpage.js/dist/fullpage.min";

// Template Component

@import "template/nav";
@import "template/footer";
@import "template/body";
@import "template/nebbiogeno-body";

h1,
h2,
h3,
h4,
h5,
h6 {
  color: black;
  font-weight: bold;
}
p {
  color: #4c4c4c;
}
a,
a:hover {
  color: inherit;
  text-decoration: none;
}

html,
body {
  height: 100%;
}
body {
  font-family: $font-family-base;
  font-size: 1.25rem;
  font-weight: 300;
}

.btn-secondary {
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0.45rem 2.2rem;
  &:focus {
    color: white;
  }
  &:hover {
    color: white;
    background-color: $secondary-dark;
  }
}
.btn-rounded {
  border-radius: 20px;
}

.bold {
  font-weight: bold;
}

body.using-mouse :focus {
  outline: none;
  box-shadow: none;
}

@include media-breakpoint-down(xl) {
  body {
    font-size: 1.125rem;
  }
}
@include media-breakpoint-down(md) {
  body {
    font-size: 1rem;
  }
  .btn-secondary {
    padding: 12px;
    line-height: 1;
  }
}
