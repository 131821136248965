footer {
  font-size: 14px;
  color: white;
  background-color: #706f6f;
  a {
    text-decoration: underline;
  }
}

@include media-breakpoint-down(lg) {
  footer {
    .text-container {
      background-color: #7e7d7d;
    }
  }
}
